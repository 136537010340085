/* eslint-disable no-sparse-arrays */
"use client";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { RenderAdsScript } from "@/app/lib/ads/renderScrip";
import { PrebidScript } from "@/app/lib/ads/prebidScript";
import { useResponsive } from "@/app/hooks/useResponsive";
import { saniticePaths } from "@/app/helpers/sanitizers/sanitizeUrl";
import style from "@/app/styles/Atoms/Advertising.module.scss";

export default function Advertising({
	typeAd = undefined,
	urlPath = "",
	isAside = false,
	position = 1,
	contentType,
}: Props) {
	const adRef = useRef<HTMLInputElement | any>({});
	const [adDiv, setAdDiv] = useState<any>(<></>);
	const { isMobile } = useResponsive();
	const lazyLoad_fetchmargin = isMobile ? 50 : 500;
	const lazyLoad_rendermargin = isMobile ? 20 : 200;
	const lazyLoad_scaling = isMobile ? 2.0 : 2.0;
	const id = uuid();
	const adPosition = position === 1 ? "atf" : "btf";
	const adPath = `${typeAd?.path}`;

	useEffect(() => {
		if (!typeAd) return;
		const renderAds = async () => {
			Promise.all([PrebidScript, RenderAdsScript]).then(() => {
				if (
					typeof window === "undefined" ||
					typeof window.googletag === "undefined" ||
					// @ts-ignore
					typeof window.pbjs === "undefined"
				)
					return;

				const { googletag } = window;
				// @ts-ignore
				const { pbjs } = window;
				const PREBID_TIMEOUT = 3000;
				const FAILSAFE_TIMEOUT = 3000;
				const validSizes = [
					[300, 250],
					[320, 50],
					[300, 600],
					[728, 90],
					[970, 250],
				];

				const appnexusValues = () => {
					for (let i = 0; i < typeAd?.size.length; i++) {
						const [width, height] = typeAd?.size[i];

						if (
							validSizes.some(
								([validWidth, validHeight]) =>
									width === validWidth && height === validHeight
							)
						) {
							if (width === 300 && height === 250) {
								return 30218954;
							} else if (width === 300 && height === 600) {
								return 30218956;
							} else if (width === 728 && height === 90) {
								return 30218955;
							} else if (width === 320 && height === 50) {
								return 30218959;
							} else if (width === 970 && height === 250) {
								return 30218965;
							}
							break;
						}
					}
				};

				const adUnits = [
					{
						code: adPath,
						mediaTypes: {
							banner: {
								sizes: typeAd?.size,
							},
						},
						bids: [
							{
								bidder: "appnexus",
								params: {
									placementId: appnexusValues(),
								},
							},
							{
								bidder: "rubicon",
								params: {
									accountId: 16302,
									siteId: 119760,
									zoneId: 566574,
								},
							},
							{
								bidder: "smartadserver",
								params: {
									siteId: 621141,
									pageId: 1889906,
								},
							},
						],
					},
				];

				googletag.cmd = googletag.cmd || [];

				pbjs.que = pbjs.que || [];

				const initAdserver = () => {
					if (pbjs.initAdserverSet) return;
					pbjs.initAdserverSet = true;
					googletag.cmd.push(() => {
						pbjs.que.push(() => {
							pbjs.setTargetingForGPTAsync();
						});
					});
				};

				pbjs.que.push(function () {
					pbjs.addAdUnits(adUnits);
					pbjs.requestBids({
						bidsBackHandler: initAdserver,
						timeout: PREBID_TIMEOUT,
					});
				});

				const to = setTimeout(function () {
					initAdserver();
				}, FAILSAFE_TIMEOUT);

				googletag.cmd.push(() => {
					const mapping = googletag
						.sizeMapping()
						.addSize(typeAd?.map[0]?.viewport, typeAd?.map[0]?.slot)
						.addSize(typeAd?.map[1]?.viewport, typeAd?.map[1]?.slot)
						.build();

					googletag.pubads().collapseEmptyDivs();
					googletag?.pubads().enableLazyLoad({
						fetchMarginPercent: lazyLoad_fetchmargin,
						renderMarginPercent: lazyLoad_rendermargin,
						mobileScaling: lazyLoad_scaling,
					});

					const typeOfContent =
						contentType === "storypage"
							? "notas"
							: contentType === "banner"
							? "videos"
							: "";
					const slot = googletag
						.defineSlot(
							`${adPath}${saniticePaths(urlPath)}${typeOfContent}`,
							typeAd?.size,
							id
						)
						.defineSizeMapping(mapping)
						.addService(googletag.pubads())
						.setTargeting("url", `${typeAd?.path.slice(6)}${urlPath}`)
						.setTargeting("position", adPosition)
						.setTargeting("slot", `slot_${position}`)
						.setTargeting(
							"skey",
							(window.location.search.match(/skey=(\w+)/) || [, ""])[1]
						);

					googletag.enableServices();
					googletag.cmd.push(() => {
						googletag.display(id);

						setInterval(() => {
							googletag.pubads().refresh([slot]);
						}, 30000);
					});
				});
				clearTimeout(to);
			});
		};

		const renderAdDiv = () => {
			setAdDiv(
				<div
					id={id}
					ref={adRef}
					className={`
            ${style["advertising__DivAds"]}
            ${isAside ? `${style["advertising__DivAds--aside"]}` : ""}
          `}
					dangerouslySetInnerHTML={{ __html: "PUBLICIDAD" }}
				/>
			);
		};

		renderAdDiv();

		if (position !== 1) {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							renderAds();
							observer.unobserve(entry.target);
						}
					});
				},
				{
					rootMargin: `-${lazyLoad_rendermargin}px`,
				}
			);

			if (adRef.current) {
				observer.observe(adRef.current);
			}

			return () => {
				observer.disconnect();
				window?.googletag?.cmd?.push(() => {
					window?.googletag?.destroySlots();
				});
			};
		} else {
			renderAds();
			return () => {
				window?.googletag?.cmd?.push(() => {
					window?.googletag?.destroySlots();
				});
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeAd, position]);

	return (
		<>
			<div ref={adRef}>{typeAd && adDiv}</div>
		</>
	);
}

type Props = {
	typeAd: any;
	urlPath: string;
	contentType?: string;
	isAside?: boolean;
	position?: number;
};
