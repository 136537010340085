"use client";

import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
	FreeMode,
	Navigation,
	Pagination,
	Thumbs,
	Keyboard,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import style from "@/app/styles/Molecules/Slider.module.scss";

import SingleVideo from "../SingleVideo/SingleVideo";

type Slide = {
	cardItem: any;
	typeMedia?:string;
	isLiveBlog?:boolean;
};

export default function MultiVideoSlider({ cardItem, typeMedia, isLiveBlog }: Slide) {
	const videosArray: any[] = cardItem ?? [];
	const [activeSlide, setActiveSlide] = useState(false);
	return (
		<div className={`${style["slider__wrapper"]}`}>
			<Swiper
				keyboard={{
					enabled: true,
				}}
				navigation={{
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				}}
				modules={[FreeMode, Thumbs, Keyboard, Navigation, Pagination]}

				pagination={{
					clickable: true,
					el: `.${style["slider__bullets"]}`,
				}}
				className={`mySwiper ${style["slider__pagination"]}`}
				onSlideChange={() => {
					setActiveSlide(true);
					setTimeout(() => {
						setActiveSlide(false);
					}, 200);
				}}
			>
				{videosArray.map((video, index) => {
					video.description = video?.description || video?.title;
					return (
						<SwiperSlide key={`swiper-slide-${index}`}>
							<SingleVideo
								cardItem={video}
								manualPause={activeSlide}
								isAlternative={false}
								alterTitle={video?.title}
								typeMedia={typeMedia}
								isLiveBlog={isLiveBlog}
							/>
						</SwiperSlide>
					);
				})}
				<div className={`${style["slider__bullets"]}`} />
				<div>
					<div
						className={`swiper-button-prev`}
						style={{
							border: "none",
							borderRadius:'50%',
						}}
					/>

					<div
						className={`swiper-button-next `}
						style={{
							border: "none",
							borderRadius:'50%',
							right:'18px'
						}}
					/>
				</div>
			</Swiper>
		</div>
	);
}
