import { usePathname } from "next/navigation";
import sanitizeText from "@/app/helpers/sanitizers/sanitizeText";
import style from "@/app/styles/Molecules/StoryPageHeadline.module.scss";
import { setTimeDiffText } from "@/app/helpers";
import DateModified from "../../atoms/Texts/Date/DateModified";

type Props = {
	cardItem?: any;
};

const StoryPageHeadline = ({ cardItem = {} }: Props) => {
	const {
		title = null,
		editorialTitle = null,
		author = null,
		published = null,
		changed = null,
		summary = null,
		dateTime = null,
		dateModified,
	} = cardItem ?? {};
	const pathName = usePathname();
	const isPressRoom = pathName?.includes("sala-de-prensa");
	const isProfile = pathName?.includes("/perfiles/");
	const { 0: textModify, 2: hours } = setTimeDiffText(new Date(dateModified));
	return (
		<header className={`${style["spHeadline__wrapper"]}`}>
			<h1 className={`${style["spHeadline__title"]}`}>
				{editorialTitle || title}
			</h1>

			{!isProfile && (
				<>
					<p className={`${style["spHeadline__summary"]}`}>{summary}</p>
					<div className={`${style["spHeadline__wrapperTime"]}`}>
						<div className={`${style["spHeadline__wrapperTimeAuthor"]}`}>
						<time
							dateTime={dateTime ?? dateModified}
							className={`${style["spHeadline__dateTimeText"]}`}
							suppressHydrationWarning
						>
							{ `${changed ?? published} CST` }
						</time>

							{!isPressRoom && author && (
								<span className={`${style["spHeadline__separator"]}`}>|</span>
							)}

							{author && (
								<p className={`${style["spHeadline__text"]}`}>
									{sanitizeText({
										text: author ?? "sin autor",
										isTruncated: true,
										isCapitalized: false,
										maxCharacters: 40,
									})}
								</p>
							)}
						</div>
						{dateModified && (
							<div
								className={`${style["spHeadline__lastUpdate__timeSeparate"]}`}
							>
								-
							</div>
						)}
						<div className={`${style["spHeadline__lastUpdate--tablet"]}`}>
							<span className={`${style["spHeadline__lastUpdate__info"]}`}>
								<DateModified isText={hours < 12} textModify={textModify} dateModified={dateModified} typeMedia={""} />
							</span>
						</div>
					</div>
					<div className={`${style["spHeadline__lastUpdate"]}`}>
						<span className={`${style["spHeadline__lastUpdate__info"]}`}>
							<DateModified isText={hours < 12} textModify={textModify} dateModified={dateModified} typeMedia={""} />
						</span>
					</div>
					{/* Para el lanzamiento pidieron comentar esto, lo van a mejorar */}
					{/* {!isPressRoom &&
						<div className={`${style["spHeadline__wrapperTimeAuthor"]}`}>
							<p className={`${style["spHeadline__text"]} ${style["spHeadline__text--secondary"]}`}>
								<Icon name='clock' /> Actualizado: {change}
							</p>
						</div>
					} */}
				</>
			)}
		</header>
	);
};
export default StoryPageHeadline;
