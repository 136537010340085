"use client";
import { useState, useEffect } from "react";
import PlayerVodV2 from "@/app/components/molecules/PlayerVodV2/PlayerVodV2";
import style from "@/app/styles/Molecules/EmbedPlayer.module.scss";
import "@/app/styles/Molecules/StoryPageVerticalVideo.scss";
import Image from "next/image";
type Props = {
	videoCode: string;
	dataValues?: any;
	verticalVideo?: any;
	isliveblog?: boolean;
};

const EmbedPlayer = ({
	videoCode = "",
	dataValues = undefined,
	verticalVideo,
	isliveblog = false,
}: Props) => {
	const [isIos, setIsIos] = useState(false);
	const [isFireFox, setisFirefox] = useState(false);
	const [isPlaying, setPlaying] = useState<boolean>(false);

	const [finalVideoId] = useState<string>(videoCode);
	useEffect(() => {
		setIsIos(
			window?.navigator?.userAgent?.includes("iPhone OS") ||
				window?.navigator?.userAgent?.includes("iPad") ||
				!window?.navigator?.userAgent?.includes("Chrome")
		);
		setisFirefox(window?.navigator?.userAgent?.includes("Firefox"));
	}, []);
	useEffect(() => {
		if (isIos && document.querySelector(".videoVertical") && isPlaying) {
			document.querySelectorAll(".videoVertical").forEach((element) => {
				element.classList.add("jw-vertical-ios");
			});
		} else {
			document.querySelectorAll(".videoVertical").forEach((element) => {
				element.classList.add("jw-vertical-chrome");
			});
		}
		return () => {};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPlaying]);
	useEffect(() => {
		const vidObjScipt = document.createElement("script");
		vidObjScipt.setAttribute("type", "application/ld+json");
		vidObjScipt.setAttribute("id", `vos-${dataValues?.video}`)
		
		const videoData = {
			"@context": "https://schema.org",
			"@type": "VideoObject",
			"name": dataValues?.title,
			"description": dataValues?.description,
			"thumbnailUrl": dataValues?.src?.includes(".jpg") ? [dataValues?.src] : `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}/assets/placeholder_.svg`,
			"uploadDate": dataValues?.uploadDate,
			"duration": dataValues?.duration,
			"contentUrl": dataValues?.contentUrl,
			"embedUrl": `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}/preview-video/?idvideo=${dataValues?.video}`,
		}
		vidObjScipt.textContent = JSON.stringify(videoData);
		document?.head?.appendChild(vidObjScipt);
	}, [dataValues?.contentUrl, dataValues?.description, dataValues?.duration, dataValues?.src, dataValues?.title, dataValues?.uploadDate, dataValues?.video]);
	return finalVideoId !== "" ? (
		<>
			{!dataValues ? (
				<div className={`${style["embedPlayer__vidWrapper"]}`}>
					<PlayerVodV2
						idVideo={finalVideoId}
						autoplay={false}
						mute={false}
						manualPlay={isPlaying}
						currentPlaceholder="/assets/placeholder_.svg"
						isVerticalVideo={verticalVideo}
					/>
				</div>
			) : (
				<div
					className={`${style["embedPlayer__nmasVideoWrapper"] + " " + (isliveblog === verticalVideo ? style.liveblog : "" )} 
						${!isPlaying ? "" : `${style[`embedPlayer__nmasVideoWrapper--playing`]}`}
								${
									isPlaying && verticalVideo
										? `${style["embedPlayer__wrapperVerticalVideo"]}`
										: ""
								}

								${verticalVideo && style["embedPlayer__nmasVideoWrapperVertical"] + " " + (isliveblog ? style.liveblogVer : "" ) }
							`}
				>
					<figure
						onClick={() => {
							setPlaying(true);
						}}
						className={`${
							verticalVideo && style["embedPlayer__verticalVideo"]
						}`}
					>
						<div>
							<div className="nmasVideoIcon">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 240 240"
									focusable="false"
								>
									<path d="M62.8,199.5c-1,0.8-2.4,0.6-3.3-0.4c-0.4-0.5-0.6-1.1-0.5-1.8V42.6c-0.2-1.3,0.7-2.4,1.9-2.6c0.7-0.1,1.3,0.1,1.9,0.4l154.7,77.7c2.1,1.1,2.1,2.8,0,3.8L62.8,199.5z" />
								</svg>
							</div>
						</div>
						<Image alt={dataValues?.alt} src={dataValues?.src} width="368" height="207" />
					</figure>
					{isPlaying && (
						<PlayerVodV2
							idVideo={finalVideoId}
							autoplay
							mute={false}
							manualPlay={isPlaying}
							cmsid
							currentPlaceholder={
								dataValues?.src ? dataValues?.src : "/assets/placeholder_.svg"
							}
							isVerticalVideo={verticalVideo}
						/>
					)}
				</div>
			)}
		</>
	) : (
		<></>
	);
};

export default EmbedPlayer;
