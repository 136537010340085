"use client";
import { icon } from "./types/iconTypes";
import iconList from "./iconList";

export default function Icon({ name, className, onClick, title, style }: icon) {
	const icon = iconList[name];
	return !icon ? (
		(() => {
			console.warn(`El icono "${name}" no está definido.`);
			return null;
		})()
	) : (
		<span
			className={className}
			onClick={onClick}
			dangerouslySetInnerHTML={{ __html: icon }}
			title={title}
			style={style}
		/>
	);
}
