'use client'
import style from "@/app/styles/Atoms/LineClampWrapper.module.scss"

type LineclampWrapperType = {
	lineClamp?: number;
	mobileLineClamp?: number;
	orientation?: "horizontal" | "vertical";
	height?: number;
	widthPercentage?: number;
	children?: any;
	id?: string;
};

export default function LineclampWrapper({
	lineClamp = 3,
	orientation = "vertical",
	height,
	widthPercentage,
	children,
	id,
	mobileLineClamp,
}: LineclampWrapperType) {
	return (
		<div
			className={`${style["lineClamp__wrapper"]}`}
			id={id}
			// model={{
			// 	lineClamp,
			// 	mobileLineClamp,
			// 	height,
			// 	widthPercentage,
			// 	orientation,
			// }}
		>
			{children}
		</div>
	);
};

export type { LineclampWrapperType };
