const sanitizeUrl = (url: string, regex: RegExp) => {
	return url
		.normalize("NFD")
		.toLowerCase()
		.replace(/\s/g, "-")
		.replace(regex, "");
};

export function saniticePaths(url: string) {
	const segments: string[] = url.split("/").filter((segment) => segment !== "");
	const validSegments: string[] = [];

	for (const segment of segments) {
		const dashCount = (segment.match(/-/g) || []).length;
		if (dashCount > 4) break;
		validSegments.push(segment);
	}

	if (
		validSegments.length > 0 &&
		validSegments[validSegments.length - 1] === "videos"
	) {
		validSegments.pop();
	}

	return validSegments.length > 0 ? `/${validSegments.join("/")}/` : "/";
}

export default sanitizeUrl;
