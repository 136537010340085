"use client";
import { useState, Fragment, useEffect, useRef } from "react";
import { usePathname } from "next/navigation";
import parse from "html-react-parser";
import { v4 as uuid } from "uuid";
import indexAds from "@/app/components/atoms/Ads/Ads";
import Advertising from "@/app/components/atoms/AdContainer/Advertising";
import EmbedPlayer from "@/app/components/molecules/EmbedPlayer/EmpedPlayer";
import style from "@/app/styles/Molecules/StoryPageBody.module.scss";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import ButtonSeeMore from "../../atoms/Buttons/ButtonSeeMore/ButtonSeeMore";
import Image from "next/image";
import useSocialScripts from "@/app/hooks/useSocialScripts";
import { HtmlParserProps } from "@/app/components/molecules/HtmlParser/interfaces/HtmlParser.interface";

declare const window: WindowWithDataLayer;

export default function HtmlParserLB({
	bodyHtml = "",
	typeMedia = "",
	hasButton,
	isLiveBlog = false,
	isMain,
	isSummary,
	isVisibleObserver,
	dataLayer,
	isPinned,
}: HtmlParserProps) {
	const [htmlParse, setHtmlParse] = useState<React.ReactNode>(null);
	const [showMore, setShowMore] = useState(false);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const pathName = usePathname() || "";

	const socialScripts = useSocialScripts(bodyHtml, isVisibleObserver || false);

	useEffect(() => {
		if (socialScripts) {
			socialScripts();
		}
	}, [socialScripts]);

	useEffect(() => {
		if (bodyHtml) {
			type MediaData = {
				video?: any;
				src?: string;
				alt?: string;
				uploadDate?: string;
				title?: string;
				contentUrl?: string;
				duration?: string;
				description?: string;
			};
			let currentPosition = 1;
			const screenSize =
				window?.matchMedia("(min-width: 801px)")?.matches &&
				!navigator?.userAgent?.match(/(Android|iPhone|iPod|iPad|Tablet)/);

			const replaceNode = (node: any) => {
				if (node?.attribs) {
					if (node?.attribs?.class === "divAds") {
						currentPosition++;

						return (
							!pathName?.includes("/preview/") && (
								<>
									<Advertising
										typeAd={
											screenSize
												? indexAds?.desktop?.combo
												: indexAds?.mobile?.combo
										}
										urlPath={pathName}
										position={currentPosition}
									/>
								</>
							)
						);
					} else if (node?.attribs?.class === "nMasVideo") {
						const isVerticalVideo =
							node?.attribs["data-type"] === "9/16" || false;
						const {
							"data-video": video,
							"data-src": src,
							"data-alt": alt,
							"data-uploaddate": uploadDate,
							"data-title": title,
							"data-contenturl": contentUrl,
							"data-duration": duration,
							"data-description": description,
						} = node?.attribs;
						const nMasVideoData: MediaData = {
							video,
							src,
							alt,
							uploadDate,
							title,
							contentUrl,
							duration,
							description,
						};

						return (
							<EmbedPlayer
								dataValues={nMasVideoData}
								videoCode={nMasVideoData?.video}
								verticalVideo={isVerticalVideo}
								isliveblog={isLiveBlog}
							/>
						);
					} else if (node?.attribs?.class === "nmas-image") {
						const { "data-src": src, "data-alt": alt } = node?.attribs;

						const nMasImage: MediaData = {
							src,
							alt,
						};

						return (
							<Image
								className={isLiveBlog ? "img-body" : ""}
								quality={100}
								src={nMasImage?.src || "/assets/placeholder_.svg"}
								alt={nMasImage?.alt || "Imagen no disponible"}
								loading="lazy"
								sizes="(min-width:1200px) 50vw, (min-width:1024px) 60vw, (min-width:768px) 80vw, (min-width: 540px) 100vw"
								width={1920}
								height={1280}
							/>
						);
					} else if (node?.name === "a") {
						node.attribs.id = uuid();
					}
				}
				return null;
			};

			const parseHTML = () => {
				const options = {
					replace: (node: any) => replaceNode(node),
				};
				const newHtml = parse(bodyHtml, options);
				setHtmlParse(newHtml);
			};

			parseHTML();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodyHtml, pathName, showMore]);

	const renderBody = () => {
		return (isLiveBlog && showMore && !isSummary) ||
			!isLiveBlog ||
			isSummary ||
			isMain ? (
			<>
				<Fragment key={`bodyHtml-${uuid()}`}>
					<div ref={contentRef}>{htmlParse}</div>
				</Fragment>
				<div
					style={{ display: "none" }}
					key={`bodyHtml-${uuid()}`}
					dangerouslySetInnerHTML={{
						__html: bodyHtml?.replace(/\n/g, ""),
					}}
				/>
			</>
		) : null;
	};

	return (
		<>
			<div
				className={`
					${style["spBody__bodyText"]}
					${
						isLiveBlog && !isPinned
							? style["spBody__bodyText__liveBlog"]
							: isLiveBlog && isPinned
							? style["spBody__bodyText__liveBlog--pinned"]
							: ""
					}
					
					${
						typeMedia && typeMedia === "multiVideo"
							? style["spBody__bodyText__liveBlog--multiVideo"]
							: typeMedia && typeMedia === "multiImage"
							? style["spBody__bodyText__liveBlog--multiImage"]
							: ""
					}
				`}
			>
				<div
					className={`
						${style["spBody__HTMLBodyText"]}
						${isLiveBlog ? style["spBody__HTMLBodyText__liveBlog"] : ""}
						${isLiveBlog && isMain ? style["spBody__HTMLBodyText__liveBlog__main"] : ""}
					`}
					style={{ width: "100%" }}
				>
					{renderBody()}
				</div>
			</div>

			{isLiveBlog && !hasButton && !isMain && !isSummary && (
				<ButtonSeeMore
					type="liveblog"
					onClick={() => {
						setShowMore(!showMore);
						if (contentRef.current) {
							contentRef.current.style.display = showMore ? "none" : "block";
						}
					}}
					textBtn={showMore ? "Ver Menos" : "Ver Más"}
					className={showMore ? "" : style["spBody__close"]}
					dataLayer={dataLayer}
					isPinned={isPinned}
				/>
			)}
		</>
	);
}
