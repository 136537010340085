const indexAds = Object.freeze({
	desktop: {
		pixel: {
			size: [[1, 1]],
			map: [
				{
					viewport: [0, 10000],
					slot: [[1, 1]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		pixelTwo: {
			size: [[1, 2]],
			map: [
				{
					viewport: [0, 10000],
					slot: [[1, 2]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		comboSuper: {
			size: [
				[728, 90],
				[970, 90],
				[955, 90],
			],
			map: [
				{
					viewport: [1024, 768],
					slot: [
						[970, 90],
						[955, 90],
						[750, 200],
						[728, 90],
					],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		comboBanner: {
			size: [
				[728, 90],
				[970, 90],
				[955, 90],
			],
			map: [
				{
					viewport: [1024, 730],
					slot: [
						[970, 90],
						[955, 90],
						[728, 90],
					],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		combo: {
			size: [[300, 250]],
			map: [
				{
					viewport: [0, 0],
					slot: [[300, 250]],
				},
				{
					viewport: [640, 480],
					slot: [[300, 250]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		combo2: {
			size: [
				[300, 250],
				[300, 600],
			],
			map: [
				{
					viewport: [0, 0],
					slot: [
						[300, 250],
						[300, 600],
					],
				},
				{
					viewport: [640, 480],
					slot: [
						[300, 250],
						[300, 600],
					],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		combo3: {
			size: [
				[160, 250],
				[300, 600],
				[160, 600],
				// [300, 250],
			],
			map: [
				{
					viewport: [0, 0],
					slot: [
						[160, 600],
						[300, 600],
						[160, 250],
						// [300, 250],
					],
				},
				{
					viewport: [640, 480],
					slot: [
						[160, 600],
						[300, 600],
						[160, 250],
						// [300, 250],
					],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		comboMaster: {
			size: [
				[728, 90],
				[955, 90],
				[970, 250],
				[970, 90],
			],
			map: [
				{
					viewport: [1024, 768],
					slot: [
						[970, 90],
						[955, 90],
						[750, 200],
						[728, 90],
					],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		comboMasterSmall: {
			size: [[728, 90]],
			map: [
				{
					viewport: [1024, 768],
					slot: [[728, 90]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
	},

	mobile: {
		simple: {
			size: [[1, 1]],
			map: [
				{
					viewport: [0, 0],
					slot: [[300, 250]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		banner: {
			size: [[320, 50]],
			map: [
				{
					viewport: [0, 0],
					slot: [[320, 50]],
				},
				{
					viewport: [640, 480],
					slot: [[320, 50]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		combo: {
			size: [[320, 50]],
			map: [
				{
					viewport: [0, 0],
					slot: [
						[300, 250],
						[320, 50],
					],
				},
				{
					viewport: [640, 480],
					slot: [
						[300, 250],
						[320, 50],
					],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		combo2: {
			size: [[300, 250]],
			map: [
				{
					viewport: [0, 0],
					slot: [[300, 250]],
				},
				{
					viewport: [640, 480],
					slot: [[300, 250]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		combo3: {
			size: [
				[320, 50],
				[300, 250],
			],
			map: [
				{
					viewport: [0, 0],
					slot: [[300, 250]],
				},
				{
					viewport: [640, 480],
					slot: [[300, 250]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
		comboMobile: {
			size: [[300, 600]],
			map: [
				{
					viewport: [0, 0],
					slot: [[300, 600]],
				},
				{
					viewport: [640, 480],
					slot: [[300, 600]],
				},
			],
			path: `/5644/televisa.nmas`,
		},
	},
});

export default indexAds;
