'use client'
import Image from "next/image";
import style from "@/app/styles/Molecules/SingleImage.module.scss"

type Props = {
	cardItem?: any;
	aspect?: string;
	height?: string;
	image?: any;
	isVariantBulletin?: boolean;
	isliveblog?: boolean;
	isPinned?:boolean;
};

export default function SingleImage({
	aspect,
	height,
	cardItem,
	isVariantBulletin = false,
	isliveblog = false,
	isPinned,
}: Props) {
	const singleImage = cardItem?.thumbnail;
	let crops;
	if (cardItem?.images?.length > 1) {
		crops = cardItem?.images?.[1].image;
	}
	const cropSelected =
		(cardItem?.cropType === "1/1"
			? crops?.corte_1_1
			: cardItem?.cropType === "16/9"
			? crops?.corte_16_9
			: crops?.corte_9_16) ||
		singleImage?.imageDesktopUrl?.webp ||
		singleImage?.imageUrl?.webp ||
		singleImage?.imageUrl?.jpeg;
	// TODO
	return (
		<figure
			className={`${style["singleImage__imageWrapper"] + " " + (isliveblog ? style?.liveblog : "")} ${isliveblog && isPinned ? `${style['singleImage__liveblog--pinned']}` : ''}`}
			style={{ aspectRatio: cardItem?.cropType ?? aspect, height }}
		>
			<div className={`${style["singleImage__image"]}`}>
				{isliveblog ?
					<Image
						src={cropSelected || "/assets/placeholder_.svg"}
						alt={singleImage?.alt || "Imagen no disponible"}
						loading="eager"
						width={1920}
						height={1280}
						quality={100}
						sizes="(min-width:1200px) 50vw, (min-width:1024px) 60vw, (min-width:768px) 80vw, (min-width: 540px) 100vw"
					/>
					:
					<Image
						src={cropSelected || "/assets/placeholder_.svg"}
						alt={singleImage?.alt || "Imagen no disponible"}
						fill={true}
						loading="eager"
						quality={80}
						sizes="(min-width:2180px) 28vw, (min-width:1920px) 36vw, (min-width: 1280px) 44vw, (min-width:1024px) 52vw, (min-width:768px) 80vw, (min-width: 540px) 100vw"
					/>
				}

			</div>
			{!isVariantBulletin && (
				<figcaption
					className={`${style["singleImage__captionWrapperImage"]}`}
				>
					<p className={`${style["singleImage__captionImage"]}`}>
						{singleImage?.caption}
					</p>
				</figcaption>
			)}
		</figure>
	);
};