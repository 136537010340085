'use client'
import sanitizeText from "@/app/helpers/sanitizers/sanitizeText";
import Link from "next/link";
// import { GTMSendEvent } from "src/site-tools/analitycs/GoogleAnalitycScript"; // TODO: HOMOLOGAR CON EL MÉTODO NUEVO handleDatalayerOnClick

type Props = {
	url?: string;
	style?: any;
	children?: any;
	title?: any;
	isOnHeader?: boolean;
	target?: string;
	gaEvent?: any
};

export default function LinkWrapper ({
	url = "/",
	children,
	style,
	title = "",
	isOnHeader = false,
	target = "_self",
	gaEvent = null
}: Props) {
	const reg = /\/mundial-2022\/?|\/temas\/qatar\/?/;
	return (
		reg.test(url) ? 
			<a href={url} style={style} title={title} target={target}>
				{children}
			</a>
		 : 
		 	<Link href={sanitizeText({ text: url, isUrl: true })} prefetch={false}>
				{isOnHeader ? (
					<a
						style={style}
						title={title}
						itemProp="url"
						target={target}
						onClick={() =>{/* AQUÍ HAY QUE MANDAR EL SELECT CONTENT */}
						}
					>
						{children}
					</a>
				) : (
					<a
						style={style}
						title={title}
						target={target}
						onClick={() => {
								if (gaEvent) {
									// GTMSendEvent(gaEvent)
									// AQUÍ HAY QUE MANDAR EL SELECT CONTENT
								}
							}							
						}
					>
						{children}
					</a>
				)}
			</Link>
	);
};