"use client";
import { useState, Fragment, useEffect, ReactNode } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import parse from "html-react-parser";
import { v4 as uuid } from "uuid";
import { TopicItem } from "@/app/types/BaseImageType";
import ButtonNavFilterLigth from "@/app/components/atoms/ButtonNavFilter/ButtonNavFilterLigth";
import indexAds from "@/app/components/atoms/Ads/Ads";
import Advertising from "@/app/components/atoms/AdContainer/Advertising";
import EmbedPlayer from "@/app/components/molecules/EmbedPlayer/EmpedPlayer";
import style from "@/app/styles/Molecules/StoryPageBody.module.scss";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import ButtonSeeMore from "../../atoms/Buttons/ButtonSeeMore/ButtonSeeMore";
import Image from "next/image";
import useSocialScripts from "@/app/hooks/useSocialScripts";
import { HtmlParserProps } from "@/app/components/molecules/HtmlParser/interfaces/HtmlParser.interface";
import Script from "next/script";

declare const window: WindowWithDataLayer;

export default function HtmlParserSP({
	bodyHtml = "",
	cardItem,
	type = "",
	typeMedia = "",
	position = 1,
	total = 1,
	hasButton,
	isLiveBlog = false,
	isVisibleObserver,
	adOps,
}: HtmlParserProps) {
	const { hasTeads, hasSeedTag } = adOps || {
		hasTeads: false,
		hasSeedTag: false,
	};
	const [htmlParse, setHtmlParse] = useState<ReactNode>(null);
	const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
	const pathName = usePathname() || "";
	const pagePath = pathName;
	const isMigratedStory =
		new Date("2022-08-01") > new Date(cardItem?.published || 0);
	const backToTop = () => window?.scroll({ top: 0, behavior: "smooth" });
	const blockType = type?.replace("block_content--", "").split("_").join(" ");

	const socialScripts = useSocialScripts(bodyHtml, isVisibleObserver || false);

	useEffect(() => {
		if (socialScripts) {
			socialScripts();
		}
	}, [socialScripts]);

	useEffect(() => {
		if (bodyHtml) {
			type MediaData = {
				video?: any;
				src?: string;
				alt?: string;
				uploadDate?: string;
				title?: string;
				contentUrl?: string;
				duration?: string;
				description?: string;
			};
			let currentPosition = 1;
			const screenSize =
				window?.matchMedia("(min-width: 801px)")?.matches &&
				!navigator?.userAgent?.match(/(Android|iPhone|iPod|iPad|Tablet)/);

			const replaceNode = (node: any) => {
				if (node?.attribs) {
					if (node?.attribs?.class === "divAds") {
						currentPosition++;

						return (
							!pathName?.includes("/preview/") && (
								<>
									<Advertising
										typeAd={
											screenSize
												? indexAds?.desktop?.combo
												: indexAds?.mobile?.combo
										}
										urlPath={pathName}
										contentType={"storypage"}
										position={currentPosition}
									/>
								</>
							)
						);
					} else if (node?.attribs?.class === "nMasVideo") {
						const isVerticalVideo =
							node?.attribs["data-type"] === "9/16" || false;
						const {
							"data-video": video,
							"data-src": src,
							"data-alt": alt,
							"data-uploaddate": uploadDate,
							"data-title": title,
							"data-contenturl": contentUrl,
							"data-duration": duration,
							"data-description": description,
						} = node?.attribs;
						const nMasVideoData: MediaData = {
							video,
							src,
							alt,
							uploadDate,
							title,
							contentUrl,
							duration,
							description,
						};

						return (
							<EmbedPlayer
								dataValues={nMasVideoData}
								videoCode={nMasVideoData?.video}
								verticalVideo={isVerticalVideo}
								isliveblog={isLiveBlog}
							/>
						);
					} else if (node?.attribs?.class === "nmas-image") {
						const { "data-src": src, "data-alt": alt } = node?.attribs;

						const nMasImage: MediaData = {
							src,
							alt,
						};

						return (
							<Image
								className={isLiveBlog ? "img-body" : ""}
								quality={100}
								src={nMasImage?.src || "/assets/placeholder_.svg"}
								alt={nMasImage?.alt || "Imagen no disponible"}
								loading="lazy"
								sizes="(min-width:1200px) 50vw, (min-width:1024px) 60vw, (min-width:768px) 80vw, (min-width: 540px) 100vw"
								width={1920}
								height={1280}
							/>
						);
					} else if (node?.name === "a") {
						node.attribs.id = uuid();
					}
				}
				return null;
			};

			const parseHTML = () => {
				const options = {
					replace: (node: any) => replaceNode(node),
				};
				const newHtml = parse(bodyHtml, options);
				setHtmlParse(newHtml);
			};

			parseHTML();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodyHtml, pathName]);

	useEffect(() => {
		if (!scriptLoaded && hasTeads) {
			const script = document.createElement("script");
			script.setAttribute("type", "text/javascript");
			script.setAttribute("class", "teads");
			script.setAttribute("async", "true");
			script.src = "//a.teads.tv/page/161928/tag";
			const position = document.querySelector("body");
			position?.appendChild(script);
			setScriptLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!pathName?.includes("preview") && (
				<>
					{hasSeedTag && (
						<Script
							src="https://t.seedtag.com/t/7819-0486-01.js"
							strategy="beforeInteractive"
							async
						/>
					)}
				</>
			)}
			<div
				className={`
					${style["spBody__bodyText"]}
					${
						typeMedia && typeMedia === "multiVideo"
							? style["spBody__bodyText__liveBlog--multiVideo"]
							: typeMedia && typeMedia === "multiImage"
							? style["spBody__bodyText__liveBlog--multiImage"]
							: ""
					}
				`}
			>
				<div
					className={`
						${style["spBody__HTMLBodyText"]}
					`}
					style={{ width: "100%" }}
				>
					{isMigratedStory && !pathName?.includes("/perfiles/") ? (
						<div
							key={`bodyHtml-${uuid()}`}
							dangerouslySetInnerHTML={{ __html: bodyHtml?.replace(/\n/g, "") }}
						/>
					) : (
						<>
							<Fragment key={`bodyHtml-${uuid()}`}>
								<div>{htmlParse}</div>
							</Fragment>
							<div
								style={{ display: "none" }}
								key={`bodyHtml-${uuid()}`}
								dangerouslySetInnerHTML={{
									__html: bodyHtml?.replace(/\n/g, ""),
								}}
							/>
						</>
					)}
					<div style={{ width: "100%" }}>
						{cardItem?.term?.map((item: TopicItem) => (
							<Link href={item?.url} prefetch={false} key={uuid()}>
								<ButtonNavFilterLigth
									title={item?.name}
									requestData={() => {
										const blockDataLayer = {
											event: "select_content",
											contenidosBloque: `${cardItem?.term?.length} contenidos`,
											content_type: "Botón",
											countWidget: total,
											seccion: pagePath,
											story_principal: cardItem?.title,
											content_name: item?.name,
											eventAction: "click",
											posicionBloque: position + 1 ? position + 1 : 0,
											tamanoBloque: undefined,
											tipoBloque: upperCamelCase(blockType) ?? undefined,
											tipoElemento: "widget",
											tituloBloque: "StoryPage" ?? undefined,
										};
										handleDataLayerOnClick(
											blockDataLayer,
											position,
											pagePath,
											true
										);
									}}
								/>
							</Link>
						))}
					</div>
				</div>
			</div>

			{hasButton && (
				<ButtonSeeMore
					type="storypage"
					textBtn={"Volver arriba"}
					iconPosition={"end"}
					iconName={"arrowUp"}
					onClick={() => {
						backToTop();
						const blockDataLayer = {
							event: "select_content",
							content_type: "Button-up",
							seccion: pagePath,
							content_name: "Volver arriba",
							eventAction: "click",
							tipoBloque: upperCamelCase(blockType) ?? undefined,
							tipoElemento: "widget",
							tituloBloque: "StoryPage" ?? undefined,
						};
						handleDataLayerOnClick(blockDataLayer, position, pagePath);
					}}
				/>
			)}
		</>
	);
}
