'use client'
import React from "react";
import { usePathname } from 'next/navigation'
import LinkWrapper from "@/app/components/atoms/LinkWrapper/LinkWrapper";
import LineclampWrapper from "@/app/components/atoms/lineClamp/LineClampWrapper";
import CategoryTitle from "@/app/components/molecules/ThemeCaterogyTitle/ThemeCategoryTitle";
import showTooltipAfter from "@/app/helpers/showTooltipAfter/showTooltipAfter";
import sanitizeText from "@/app/helpers/sanitizers/sanitizeText";
import style from "@/app/styles/Molecules/BreakingMolecule.module.scss";

// import { BaseCardType } from "@/graphql/4 - widgetsDTO/models/BaseCard.model";
import { getIfBreakingWidgetIsVisble } from "@/app/helpers/dto/helpersDTO";
import { getBreakingDTO } from "@/app/helpers/dto/breakingDTO";

type Props = {
	cardData?: any // BaseCardType;
	isLoading?: boolean;
	isVariantCoverage?: boolean;
	isCategoryTitle?: boolean;
	isH1Title?: boolean;
};

export default function BreakingMolecule({
	cardData,
	isLoading = false,
	isVariantCoverage = false,
	isCategoryTitle = false,
	isH1Title = false,
}: Props) {
	const cardItem: any = getBreakingDTO(cardData, isCategoryTitle); // ESTE DEBE SER BaseCardType
	const pathName = usePathname();
	const isDark = !isLoading && cardData?.attributes?.field_visualizacion_dark;
	return (
		<>
			{getIfBreakingWidgetIsVisble(cardItem) && (
				<>
					{isCategoryTitle ? (
						<CategoryTitle title={cardItem.breakingTitle} isTheme={false}/>
					) : (
						<div className={`${style["breakingMolecule__wrapper"]}`}>
							{cardItem.isBreakingNews ? (
								<button
									type="button"
									className={`${style["breakingMolecule__label"]}`}
								>
									{cardItem?.breakingTag
										? cardItem?.breakingTag
										: "ÚLTIMO MINUTO"}
								</button>
							) : (
								<>
									{cardItem.topic && (
										<LineclampWrapper lineClamp={2}>
											<button
												type="button"
												className={`${style["breakingMolecule__label"]}`}
											>
												<LinkWrapper
													url={cardItem.topicUrl}
													title={showTooltipAfter({
														text: cardItem.topic,
													})}
												>
													{cardItem.isBreakingNews
														? "ÚLTIMO MINUTO"
														: cardItem.topic}
												</LinkWrapper>
											</button>
										</LineclampWrapper>
									)}
								</>
							)}
								{isH1Title && pathName !== "/" ? (
									<h1 className={`${style["breakingMolecule__titleH1"]}`}>
										{sanitizeText({
											text: cardItem.breakingTitle,
											isTruncated: true,
											maxCharacters: 100,
											isShowEllipsis: true,
											isShowEmptyPlaceholder: true,
										})}
									</h1>
								) : (
									<p className={`${style["breakingMolecule__title"]}`}>
										{sanitizeText({
											text: cardItem.breakingTitle,
											isTruncated: true,
											maxCharacters: 100,
											isShowEllipsis: true,
											isShowEmptyPlaceholder: true,
										})}
									</p>
								)}
							{isH1Title ? (
								<h2 className={`${style["breakingMolecule__textSubtitle"]}`}>
									{sanitizeText({
										text: cardItem.breakingDescription,
										isTruncated: true,
										maxCharacters: !isDark ? 120 : 350,
										isShowEllipsis: true,
										isShowEmptyPlaceholder: true,
									})}
								</h2>
							) : (
								<p className={`${style["breakingMolecule__text"]}`}>
									{sanitizeText({
										text: cardItem.breakingDescription,
										isTruncated: true,
										maxCharacters: 120,
										isShowEllipsis: true,
										isShowEmptyPlaceholder: true,
									})}
								</p>
							)}
						</div>
					)}
				</>
			)}
		</>
	);
};