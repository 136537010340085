import Link from "next/link";
import Icon from "@/app/components/atoms/Icon/Icon";
import styles from "@/app/styles/Atoms/Buttons/ButtonSeeMore.module.scss";
import handleSelectContentECommerce from "@/app/helpers/analytics/handleSelectContentECommerce";
interface Props {
	textBtn?: string;
	type?: "storypage" | "liveblog" | "dark";
	onClick?: () => void;
	iconName?: string;
	iconPosition?: "start" | "end";
	className?: string;
	path?: string;
	dataLayer?: any;
	isPinned?:boolean;
}

const Button = ({
	textBtn,
	type,
	onClick,
	iconName,
	iconPosition = "start",
	className = " ",
	path,
	dataLayer,
	isPinned,
}: Props) => {
	const dataLayerECommerce = {
		index: dataLayer?.index ? dataLayer?.index : 0,
		item_brand: "Boton Nueva actualizacion",
		item_category: undefined,
		item_category2: dataLayer?.currentPath || "",
		item_id: `${dataLayer?.buttonId + Math.random().toString(16).slice(2)}`,
		item_name: `${
			dataLayer?.entry?.ctNode?.[0]?.title
			?
			dataLayer?.entry?.ctNode?.[0]?.title
			: 
			dataLayer?.entry?.title
			
		}`,
		item_variant: `${
			dataLayer?.entry?.ctNode?.[0]?.type
				? dataLayer?.entry?.ctNode?.[0]?.type
				: dataLayer?.entry?.streamingInfo?.pathLive
				? "en vivo"
				: "text"
		}`,
		quantity: dataLayer?.index ? dataLayer?.index : 0,
	};

	const handleButtonClick = () => {
		if (onClick) {
			onClick();
		}

		if (type === "liveblog") {
			handleSelectContentECommerce(
				[
					{
						...dataLayerECommerce,
						item_brand: "liveBlog",
						item_category3: textBtn,
						item_list_id: "live_blog",
						item_list_name: "liveBlog",
					},
				],
				"live_blog",
				"liveBlog",
				"select_item"
			);
		}
	};
	
	const commonButtonProps = {
		className: `
          ${styles?.btn_seeMore}
          ${type === "storypage" && styles["btn_seeMore__storypage"]}
          ${type === "dark" && styles["btn_seeMore__dark"]}
          ${
						type === "liveblog" && !isPinned
							? `${styles["btn_seeMore__liveblog"]} ${className ? className : ''}`
							: isPinned
								? `${className ? className : ''} ${styles["btn_seeMore__liveblog--pinned"]}`
								: className
									? className
									: ""
					}
        `,
		"aria-label": textBtn,
		onClick: handleButtonClick,
	};

	if (path) {
		return (
			<Link href={path} passHref target="_blank">
				<button {...commonButtonProps}>
					{iconPosition === "start" && iconName && <Icon name={iconName} />}
					{textBtn}
					{iconPosition === "end" && iconName && <Icon name={iconName} />}
				</button>
			</Link>
		);
	} else {
		return (
			<button {...commonButtonProps}>
				{iconPosition === "start" && iconName && <Icon name={iconName} />}
				{textBtn}
				{iconPosition === "end" && iconName && <Icon name={iconName} />}
			</button>
		);
	}
};

export default Button;
