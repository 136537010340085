export const PrebidScript = new Promise((resolve) => {
	if (
		typeof window !== "undefined" &&
		window?.location?.href?.includes("/preview/")
	)
		return;

	if (typeof window === "undefined") {
		resolve(true);
	}

	const isRenderScript = document.head.querySelector(
		"script[id='PrebidScript']"
	);

	if (isRenderScript === null) {
		const script = document.createElement("script");
		script.src = "https://static.nmas.com.mx/js/prebid8.3.0.js";
		script.type = "text/javascript";
		script.id = "PrebidScript";
		script.async = true;

		script.onload = () => {
			clearTimeout(gptTimeOut);
			resolve(true);
		};

		const gptTimeOut = setTimeout(() => {
			document.head.appendChild(script);
		}, 2000);
	} else {
		resolve(true);
	}
});
