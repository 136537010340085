'use client'

type BaseSnackbarType = {
	isOpenState: boolean;
	handleCloseFunction: () => void;
	label?: string;
	labelColor?: string;
	bgColor?: string;
	autoHideDuration?: number;
	isAutoHide?: boolean;
	isShowSideIcon?: boolean;
	snackbarSideIcon?: string | undefined;
};

const BaseSnackbar = ({
	isOpenState = false,
	label = "",
	bgColor = '#00283b',
	labelColor = 'white',
}: BaseSnackbarType) => {
	return (
		<>
		{	
			isOpenState && 
			<span style={{
				position: "fixed", bottom:"8px", left: "50%", transform: "translate(-50%, 0)",
				backgroundColor:bgColor, padding:"16px", borderRadius: "3px", color: labelColor,  
				zIndex: 20,}} 
			>{label}</span>
		}
		</>
	);
};

export default BaseSnackbar;
