import {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useState,
} from "react";
type MuteProps = {
	children: ReactNode;
};

type InitialProps = {
	isMute: boolean;
	setIsMute: Dispatch<SetStateAction<boolean>>;
};

export const ToggleMuteContext = createContext<InitialProps>({
	isMute: false,
	setIsMute: () => {},
});

export const ToggleMuteProvider = ({ children }: MuteProps) => {
	const [isMute, setIsMute] = useState(true);
	return (
		<ToggleMuteContext.Provider
			value={{
				isMute, 
				setIsMute, 
			}}
		> 
			{children} 
		</ToggleMuteContext.Provider>
	);
};
   