'use client'
import style from "@/app/styles/Atoms/ButtonNavFilter.module.scss"

export default function ButtonNavFilterLigth({ title, isActive, requestData }: ButtonNavFilterLigthTypes) {
	const buttonClick = () => {
		if (requestData !== undefined) {
			requestData();
		}
	};

	return (
		<button
			className={`${style["buttonNavFilter__ligth"]}`}
			disabled={isActive}
			onClick={ () => buttonClick()}
		>
			{title}
		</button>
	);
};

type ButtonNavFilterLigthTypes = {
	title?: string;
	isActive?: boolean;
	requestData?: Function;
};