'use client'
import Image from "next/image";
// import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
// import LineclampWrapper from "@/components/atoms/lineClamp/LineClampWrapper";
import style from "@/app/styles/Molecules/CardVideoStoryPage.module.scss"
import Icon from "@/app/components/atoms/Icon/Icon";

type Props = {
	typeOfMedia?: any;
	image?: any;
	video?: any;
	onChangeSlider?: Function;
	indexVideoCard?: number;
	indexVideoPlay?: number;
	setPlayVideo?: Function;
};

export default function CardVideoStoryPage({
	typeOfMedia,
	image,
	video,
	onChangeSlider,
	setPlayVideo,
	indexVideoCard = 0,
	indexVideoPlay = 0,
}: Props) {
	const changeIndexVideoPlaylist = (newIndex: number) => {
		if (onChangeSlider !== undefined && setPlayVideo !== undefined) {
			setPlayVideo(true);
			onChangeSlider(newIndex);
		}
	};
	const {image:images} = image

	return (
		<>
			{typeOfMedia === "multiimage" ? (
				<div className={`${style["card__CardWrapperVideo"]} ${style["card__CardWrapperImage"]}`}>
					<div
						className={`
							${style["card__VideoWrapper"]}
							${style["card__ImageWrapper"]}
							${indexVideoPlay === indexVideoCard && style["card__VideoWrapper--activeShow"]}
						`}
					>
						{indexVideoPlay === indexVideoCard && (
							<></>
							// <RemoveRedEyeOutlinedIcon />
						)}
						<Image
							src={images?.corte_16_9 ?? "/assets/placeholder_.svg"}
							alt={images?.alt}
							width={368} height={207}
							priority
							quality={30}
						/>
					</div>
					<div className={`${style["card__TextWrapperVideo"]} ${style["card__TextWrapperImage"]}`}>
						{/* <LineclampWrapper lineClamp={3}> */}
							<h2 className={`${style["card__TitleCardVideo"]} ${style["card__TitleCardImage"]}`}>
								{images?.caption}
							</h2>
						{/* </LineclampWrapper> */}
					</div>
				</div>
			) : (
				<>
					{typeOfMedia === "multivideo" ? (
						<div
							className={`${style["card__CardWrapperVideo"]}`}
							onClick={() => changeIndexVideoPlaylist(indexVideoCard)}
						>
							<div
								className={`
									${style["card__VideoWrapper"]}
									${indexVideoPlay === indexVideoCard && style["card__VideoWrapper--activeShow"]}
								`}
							>
								{indexVideoPlay === indexVideoCard && (
									<>
										{/* <RemoveRedEyeOutlinedIcon /> */}
										<p className={`${style["card__PlayingTag"]}`}>
											REPRODUCIENDO
										</p>
										<Icon name="playing_eye" className={`${style['card__playingEye']}`}/>
									</>
								)}

								<Image
									src={video?.image?.imageUrl ?? video?.cover ?? "/assets/placeholder_.svg"}
									alt={video?.title}
									width={368} height={207}
									priority
									quality={30}
								/>
							</div>

							<div className={`${style["card__TextWrapperVideo"]}`}>
								<p className={`${style["card__SubtitleCard"]}`}>
									{video?.topic}
								</p>

								{/* <LineclampWrapper lineClamp={2}> */}
									<h2 className={`${style["card__TitleCardVideo"]}`}>
										{video?.title}
									</h2>
								{/* </LineclampWrapper> */}
							</div>
						</div>
					) : (
						<></>
					)}
				</>
			)}
		</>
	);
};